@media screen {
  .printOnly {
    display: none;
  }
}
@media print {
  :not(.printSection) {
    visibility: hidden;
    height: 0;
    min-height: 0px;
  }
  .printOnly {
    visibility: visible;
    display: initial;
  }
  body section,
  body .container,
  body .section {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 0 !important;
  }
  .altai-theme-content #bodyView .comeos-detailoverview.comeos-pc > section.comeos-cover.cover-large.comeos-bg-pc.highcover {
    height: 0;
    padding: 0;
  }
  comeos-masonry {
    display: none;
  }
  .altai-theme-content #bodyView .footerView {
    display: none;
  }
  .altai-theme-content #bodyView .container-content .comeos-detailoverview section.comeos-detailoverview-introduction {
    padding: 0 !important;
  }
  .printSection {
    visibility: visible;
    height: auto;
  }
  .printSection * {
    visibility: visible;
    height: auto;
  }
}
